<template>
  <registration/>
</template>

<script>
import { SET_REGISTRATION_ID } from '@/constants/mutation-types'
import { mapMutations } from 'vuex'

export default {
  name: 'RegistrationPortal',
  components: {
    Registration: () => import(/* webpackChunkName: "Registration" */ '@/components/registrations/Registration')
  },
  props: {
    registration_id: {
      type: [String, Number],
      default: 0
    }
  },
  created () {
    if (this.registration_id !== 0 && this.registration_id !== null && this.registration_id.toString() !== '') {
      this.setRegistrationId(this.registration_id)
    }
  },
  methods: {
    ...mapMutations({
      setRegistrationId: SET_REGISTRATION_ID
    })
  }
}
</script>

<style scoped>

</style>
